import Typography from "@mui/material/Typography";
import AnimatedDiv from "./AnimatedDiv";

export default function () {
  return (
    <AnimatedDiv>
      <Typography variant="h4" component="div" sx={{ paddingBottom: "24px" }}>
        Concrete
      </Typography>

      <Typography>
        Info and pictures about concrete work. Info and pictures about concrete
        work. Info and pictures about concrete work. Info and pictures about
        concrete work. Info and pictures about concrete work. Info and pictures
        about concrete work.
        <br />
        <br />
        Info and pictures about concrete work. Info and pictures about concrete
        work. Info and pictures about concrete work. Info and pictures about
        concrete work. Info and pictures about concrete work. mollit anim id est
        laborum.
      </Typography>
    </AnimatedDiv>
  );
}
