import React from "react";
import { Link, Outlet } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import "./default.scss";
import CenterPane from "./CenterPane";
import Footer from "./Footer";

export default function App() {
  return (
    <div className="siteContainer">
      <NavigationBar />
      <CenterPane>
        <Outlet />
      </CenterPane>
      <Footer />
    </div>
  );
}
