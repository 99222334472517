import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    primary: {
      main: "#9fd0f1",
    },
  },
  typography: {
    allVariants: {
      color: "#2f3041",
    },
  },
});
