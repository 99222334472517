const BannerImage = ({ image, style }) => {
  return (
    <img
      src={image}
      style={{
        width: "100%",
        borderRadius: "10px",
        maxHeight: "173px",
        objectFit: "cover",
        margin: "20px 0px 0px 0px",
        objectPosition: "center 47%",
        ...style,
      }}
    />
  );
};

export default BannerImage;
