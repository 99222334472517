import { useRef, useState } from "react";
import AnimatedDiv from "./AnimatedDiv";
import Typography from "@mui/material/Typography";
import BannerImage from "./BannerImage";
import someImage from "./images/fromBrad/26.jpg";
import { TextField } from "@mui/material";
import "./calculators.scss";

export default function () {
  const iFrameRefContainer = useRef();

  return (
    <AnimatedDiv>
      <Typography variant="h3" component="div">
        Calculators
      </Typography>
      <BannerImage image={someImage} />
      {/* <Typography variant="h4" component="div"> */}
      {/* <ConversionCalculator
        title="Ton Register to Cubic Yard"
        inputs={[
          {
            label: "Ton Register",
            conversionToBase: (currentValue) => currentValue,
            conversionFromBase: (baseValue) => baseValue,
          },
          {
            label: "Cubic Yard",
            conversionToBase: (currentValue) => currentValue * 0.27,
            conversionFromBase: (baseValue) => baseValue / 0.27,
          },
        ]}
      /> */}
      <Calculator
        title="Cubic Yard (for soil or concrete)"
        inputs={["Length (feet)", "Width (feet)", "Height (feet)"]}
        calculation={(...params) =>
          feetToYards(params.reduce((a, b) => a * b, 1))
        }
        resultName="Cubic Yards"
      />
      <Calculator
        title="Foundation Squaring"
        inputs={["Length (feet)", "Width (feet)"]}
        calculation={(a, b) => {
          const result = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
          const feet = Math.floor(result);
          const inches = ((result - feet) * 12).toFixed(1);
          return `${feet} ft. ${inches} in.`;
        }}
        resultName="Diagonal Measurement"
      />
      <FeetToDecimalCalculator />

      {/* 
          Foundation Squaring Calculator - http://www.csgnetwork.com/foundationsquarecalc.html
          cubic yard - https://www.soildirect.com/calculator/cubic-yard-calculator/
          area - https://www.soildirect.com/calculator/convert-square-feet-into-cubic-yards-calculator/
          square units - http://www.csgnetwork.com/directsqunitscalc.html
          Concrete calculator - https://www.concretenetwork.com/concrete/howmuch/calculator.htm
      */}
      <Typography>
      <table style={{ width: "50%" }}>
        {new Array(12).fill(0).map((val, i) => {
          const inch = i + 1;
          return (
            <tr>
              <td>{`${inch} inch${inch === 1 ? "" : "es"}`}</td>
              <td>{(inch / 12).toFixed(3)}</td>
            </tr>
          );
        })}
      </table>
      </Typography>
    </AnimatedDiv>
  );
}

const feetToYards = (feet) => {
  return feet / 27;
};

const FeetToDecimalCalculator = () => {
  const [feet, setFeet] = useState(0);
  const [inches, setInches] = useState(0);
  const [decimalInches, setDecimalInches] = useState(0);

  const [feetDisplay, setFeetDisplay] = useState('');
  const [inchesDisplay, setInchesDisplay] = useState('');
  const [decimalInchesDisplay, setDecimalInchesDisplay] = useState('');

  const handleChange = (event, id) => {
    const newValue = event.target.value;

    if (id === "f") {

      const newFeet = Number(newValue);

      setFeet(newFeet);
      setFeetDisplay(newValue === '' ? newValue : newFeet);

      setDecimalInches(newFeet + inches / 12);
      setDecimalInchesDisplay(String(newFeet + inches / 12));

    } else if (id === "i") {

      const newInches = Number(newValue);

      setInches(newInches);
      setInchesDisplay(newValue === '' ? newValue : newInches);

      setDecimalInches(feet + newInches / 12);
      setDecimalInchesDisplay(String(feet + newInches / 12));

    } else if (id === "d") {
      const newDecimalInches = Number(newValue);
      const newFeet = Math.floor(newDecimalInches);

      setDecimalInches(newDecimalInches);
      setDecimalInchesDisplay(newValue === '' ? newValue : newDecimalInches);

      setFeet(newFeet);
      setFeetDisplay(String(newFeet))

      setInches((newDecimalInches - newFeet) * 12);
      setInchesDisplay(String((newDecimalInches - newFeet) * 12))
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Typography variant="h5" component="div" style={{ marginBottom: 10 }}>
        Feet and Inches to Decimal Feet
      </Typography>

      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <TextField
          type="number"
          label={"Feet"}
          variant="outlined"
          value={feetDisplay}
          onChange={(event) => handleChange(event, "f")}
        />
        <TextField
          type="number"
          label={"Inches"}
          variant="outlined"
          value={inchesDisplay}
          onChange={(event) => handleChange(event, "i")}
        />
        =
        <TextField
          type="number"
          label={"Decimal Feet"}
          variant="outlined"
          value={decimalInchesDisplay}
          onChange={(event) => handleChange(event, "d")}
        />
      </div>
    </div>
  );
};

const ConversionCalculator = ({ title, inputs }) => {
  const [values, setValues] = useState(new Array(inputs.length).fill(0));

  const handleChange = (event, eventIndex) => {
    const newNumber = event.target.value;

    const baseValue = inputs[eventIndex].conversionToBase(newNumber);

    setValues(
      values.map((value, index) => inputs[index].conversionFromBase(baseValue))
    );
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Typography variant="h5" component="div" style={{ marginBottom: 10 }}>
        {title}
      </Typography>
      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        {inputs.map((input, index) => {
          return (
            <>
              <TextField
                type="number"
                key={input.label}
                label={input.label}
                variant="outlined"
                value={values[index]}
                onChange={(event) => handleChange(event, index)}
              />
              {index === inputs.length - 1 ? null : "="}
            </>
          );
        })}
      </div>
    </div>
  );
};

const Calculator = ({ title, inputs, calculation, resultName }) => {
  const [resultValue, setResultValue] = useState();
  const [inputValues, setInputValues] = useState(
    new Array(inputs.length).fill(0)
  );

  const handleChange = (event, eventIndex) => {
    const newNumber = event.target.value;
    const newInputValues = inputValues;
    newInputValues[eventIndex] = newNumber;

    console.log(newInputValues);
    console.log(...newInputValues);

    setInputValues(newInputValues);

    setResultValue(calculation(...newInputValues));
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Typography variant="h5" component="div" style={{ marginBottom: 10 }}>
        {title}
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 7,
        }}
      >
        {inputs.map((input, index) => {
          return (
            <TextField
              type="number"
              style={{ minWidth: 50 }}
              key={input}
              label={input}
              variant="outlined"
              onChange={(event) => handleChange(event, index)}
            />
          );
        })}
        =
        <TextField
          variant="standard"
          label={resultName}
          InputProps={{
            readOnly: true,
          }}
          value={resultValue}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );
};
