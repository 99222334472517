import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { getInvoices, getInvoice } from "./data";
import {
  Link,
  NavLink,
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from "./theme";
import Home from "./Home";
import AnimatedDiv from "./AnimatedDiv";
import Typography from "@mui/material/Typography";

import Contact from "./Contact";
import Services from "./Services";
import Concrete from "./Concrete";
import Handyman from "./Handyman";
import Excavating from "./Excavating";
import Employment from "./Employment";
import Pictures from "./Pictures";
import Calculators from "./Calculators";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AnimatePresence>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<Home />} />
            <Route path="services" element={<Services />}>
              <Route path="excavation" element={<Excavating />} />
              <Route path="concrete" element={<Concrete />} />
              <Route path="handyman" element={<Handyman />} />
            </Route>
            <Route path="pictures" element={<Pictures />} />
            <Route path="contact" element={<Contact />} />
            <Route path="employment" element={<Employment />} />
            <Route path="calculators" element={<Calculators />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);
