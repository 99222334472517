import AnimatedDiv from "./AnimatedDiv";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import BannerImage from "./BannerImage";
import someImage from "./images/fromBrad/4.jpg";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import EmailIcon from "@mui/icons-material/Email";
import WarehouseIcon from "@mui/icons-material/Warehouse";

export default function () {
  useEffect(() => {
    // Initialize and add the map
  }, []);
  return (
    <AnimatedDiv>
      <Typography variant="h3" component="div">
        Contact
      </Typography>
      <BannerImage image={someImage} style={{ objectPosition: "center 47%" }} />
      <Typography style={{ display: "flex", justifyContent: "flex-start" }}>
        <EmailIcon sx={{ marginRight: "5px", fill: "#2a2c40" }} />{" "}
        <a style={{ color: "inherit" }} href="mailto: compasscreek@yahoo.com">
          compasscreek@yahoo.com
        </a>
      </Typography>
      <Typography style={{ display: "flex", justifyContent: "flex-start" }}>
        <PhoneRoundedIcon sx={{ marginRight: "5px", fill: "#2a2c40" }} />{" "}
        <a href="tel:435-469-0564" style={{ color: "inherit" }}>
          435-469-0564
        </a>
      </Typography>
      <Typography style={{ display: "flex", justifyContent: "flex-start" }}>
        <WarehouseIcon sx={{ marginRight: "5px", fill: "#2a2c40" }} /> 670
        Wakara Cir, Mt Pleasant, UT 84647
      </Typography>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3077.2961407796543!2d-111.47190518385835!3d39.530372916987744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x874c15f255c0c255%3A0x72ef9359c9bb5d58!2sParish%20Construction%20And%20Property%20Maintenance%20LLC!5e0!3m2!1sen!2sus!4v1649456507097!5m2!1sen!2sus"
        width="100%"
        height="450"
        style={{
          border: 0,
          borderRadius: 10,
          marginTop: 24,
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </AnimatedDiv>
  );
}
