import Box from "@mui/material/Box";

const CenterPane = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(255,255,255,.6)",
        height: "100%",
        width: "75%",
        maxWidth: 800,
        padding: "15px 30px 30px 30px",
        borderRadius: "0px 0px 7px 7px",
        "-webkit-backdrop-filter": "blur(7px) brightness(110%)",
        "backdrop-filter": "blur(7px) brightness(110%)",
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      }}
    >
      {children}
    </Box>
  );
};

export default CenterPane;
