import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import excavating from "./images/excavation.jpg";
import concrete from "./images/concrete.jpg";
import handyman from "./images/handyman.jpg";
import { Link, useResolvedPath, useMatch } from "react-router-dom";
import servicesImage from "./images/fromBrad/2.jpg";
import BannerImage from "./BannerImage";

const scroll = () => {
  document
    .getElementById("serviceHeader")
    ?.scrollIntoView({ behavior: "smooth" });
};

const cards = [
  {
    title: "Excavating",
    description: "description description",
    imgSrc: excavating,
    path: "services/excavation",
  },
  {
    title: "Concrete",
    description: "description description",
    imgSrc: concrete,
    path: "services/concrete",
  },
  {
    title: "Handyman",
    description: "description description",
    imgSrc: handyman,
    path: "services/handyman",
  },
];

const List = ({ listItemText }) => {
  return (
    <ul style={{ columns: 2 }}>
      {listItemText.map((text) => {
        return (
          <Typography fontSize={17} key={text}>
            <li>{text}</li>
          </Typography>
        );
      })}
    </ul>
  );
};

export default function ServicesList() {
  React.useEffect(() => {
    scroll();
  }, []);
  return (
    <>
      <BannerImage
        image={servicesImage}
        style={{ objectPosition: "center 42%" }}
      />
      <List
        listItemText={[
          "Excavation",
          "Mass Grading",
          "Sewer ",
          "Storm Drain",
          "Water Lines",
          "Pressurized Irrigation",
          "Dry Utilities",
          "Turn Key Developments",
          "Residential",
          "Commercial",
          "DOT",
          "Grading",
          "Parking Lots",
          "Roadwork",
          "Sub Grade",
          "Finish Grade",
          "Asphalt",
          "Concrete Paving",
          "Demo",
        ]}
      />
    </>
    // <main>
    //   <Container sx={{ py: 3 }} maxWidth="md">
    //     <Grid container spacing={2}>
    //       {cards.map((card) => (
    //         <Grid item key={card.title} xs={12} md={4}>
    //           <RelativeLink to={card.path} style={{ textDecoration: "none" }}>
    //             <Card
    //               sx={{
    //                 height: "100%",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 color: "white",
    //               }}
    //             >
    //               <div
    //                 style={{
    //                   height: 200,
    //                   backgroundSize: "cover",
    //                   backgroundImage: `url(${card.imgSrc})`,
    //                   backgroundPosition: "50% 50%",
    //                   backgroundSize: "cover",
    //                   backgroundRepeat: "no-repeat",
    //                   backgroundAttachment: "local",
    //                 }}
    //               />
    //               <CardContent sx={{ flexGrow: 1 }}>
    //                 <Typography gutterBottom variant="h5" component="h2">
    //                   {card.title}
    //                 </Typography>
    //                 <Typography>{card.description}</Typography>
    //               </CardContent>
    //             </Card>
    //           </RelativeLink>
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </Container>
    // </main>
  );
}

const RelativeLink = ({ children, to, ...props }) => {
  let resolved = useResolvedPath(to);
  const pathname = resolved.pathname;
  const parts = pathname.split("/");
  const usedOnServicesPage = parts[1] === "services" && parts[2] === "services";

  if (usedOnServicesPage) {
    parts.splice(1, 1);
  }

  return (
    <Link to={parts.join("/")} onClick={scroll} {...props}>
      {children}
    </Link>
  );
};
