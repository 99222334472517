import { useRef, useState } from "react";
import AnimatedDiv from "./AnimatedDiv";
import Typography from "@mui/material/Typography";
import BannerImage from "./BannerImage";
import someImage from "./images/fromBrad/1.jpg";

export default function () {
  const iFrameRefContainer = useRef();

  return (
    <AnimatedDiv>
      <Typography variant="h3" component="div">
        Employment
      </Typography>
      <BannerImage image={someImage} />
      <Typography component="div">
        Looking for a "Fantastic" work opportunity? If you have experience in
        our trade, want to learn, or just want to switch careers and would like
        to be considered for employment with our company fill out the
        application below!
      </Typography>

      <div ref={iFrameRefContainer}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeMow5cn1Ej5EpuU5KcfHtbuYPoGK7Kw08THnvNwlcDcLA7pg/viewform?embedded=true"
          // onLoad={() => resizeIframeWidth(iFrameRefContainer)}
          style={{
            height: 1200,
            width: "100%",
            border: "none",
            overflow: "hidden",
          }}
        >
          Loading…
        </iframe>
      </div>
    </AnimatedDiv>
  );
}
