import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";

const Footer = ({ children }) => {
  return (
    <Box sx={{ margin: "10px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <PhoneRoundedIcon sx={{ marginRight: "3px" }} />
        <a href="tel:435-469-0564" style={{ color: "inherit" }}>
          <Typography textAlign={"center"}>435-469-0564</Typography>
        </a>
      </div>{" "}
      <Typography textAlign={"center"}>Mt. Pleasant, Utah</Typography>
    </Box>
  );
};

export default Footer;
