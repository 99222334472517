import AnimatedDiv from "./AnimatedDiv";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import BannerImage from "./BannerImage";
import Gallery from "react-grid-gallery";
import one from "./images/fromBrad/1.jpg";
import two from "./images/fromBrad/2.jpg";
import three from "./images/fromBrad/3.jpg";
import four from "./images/fromBrad/4.jpg";
import five from "./images/fromBrad/5.jpg";
import six from "./images/fromBrad/6.jpg";
import seven from "./images/fromBrad/7.jpg";
import eight from "./images/fromBrad/8.jpg";
import nine from "./images/fromBrad/9.jpg";
import ten from "./images/fromBrad/10.jpg";
import eleven from "./images/fromBrad/11.jpg";
import twelve from "./images/fromBrad/12.jpg";
import thirteen from "./images/fromBrad/13.jpg";
import fourteen from "./images/fromBrad/14.jpg";
import fifteen from "./images/fromBrad/15.jpg";
import sixteen from "./images/fromBrad/16.jpg";
import seventeen from "./images/fromBrad/17.jpg";
import eighteen from "./images/fromBrad/18.jpg";
import nineteen from "./images/fromBrad/19.jpg";
import twenty from "./images/fromBrad/20.jpg";
import twentyone from "./images/fromBrad/21.jpg";
import twentytwo from "./images/fromBrad/22.jpg";
import twentythree from "./images/fromBrad/23.jpg";
import twentyfour from "./images/fromBrad/24.jpg";
import twentyfive from "./images/fromBrad/25.jpg";
import twentysix from "./images/fromBrad/26.jpg";
import twentyseven from "./images/fromBrad/27.jpg";
import twentyeight from "./images/fromBrad/28.jpg";
import twentynine from "./images/fromBrad/29.jpg";
import thirty from "./images/fromBrad/30.jpg";
import thirtyone from "./images/fromBrad/31.jpg";
import thirtytwo from "./images/fromBrad/32.jpg";
import thirtythree from "./images/fromBrad/33.jpg";
import thirtyfour from "./images/fromBrad/34.jpg";
import thirtyfive from "./images/fromBrad/35.jpg";
import thirtysix from "./images/fromBrad/36.jpg";
import thirtyseven from "./images/fromBrad/37.jpg";
import { borderRadius } from "@mui/system";
import "./Pictures.scss";

const IMAGES = [
  { image: one },
  { image: two },
  { image: three },
  { image: four },
  { image: five },
  { image: six },
  { image: seven },
  { image: eight },
  { image: nine },
  { image: ten },
  { image: eleven },
  { image: twelve },
  { image: thirteen },
  { image: fourteen },
  { image: fifteen },
  { image: sixteen },
  { image: seventeen },
  { image: eighteen },
  { image: nineteen },
  { image: twenty },
  { image: twentyone },
  { image: twentytwo },
  { image: twentythree },
  { image: twentyfour },
  { image: twentyfive },
  { image: twentysix },
  { image: twentyseven },
  { image: twentyeight },
  { image: twentynine },
  { image: thirty },
  { image: thirtyone },
  { image: thirtytwo },
  { image: thirtythree },
  { image: thirtyfour },
  { image: thirtyfive },
  { image: thirtysix },
  { image: thirtyseven },
];

export default function () {
  function getThumnailStyle() {
    console.log(this);
    return { borderRadius: 20 };
  }

  return (
    <AnimatedDiv>
      <Typography variant="h3" component="div">
        Pictures
      </Typography>

      <Gallery
        images={IMAGES.map((imageObject) => {
          return {
            src: imageObject.image,
            thumbnail: imageObject.image,
            caption: imageObject.caption,
          };
        })}
        enableImageSelection={false}
        backdropClosesModal={true}
      />
    </AnimatedDiv>
  );
}
