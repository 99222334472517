import ServicesList from "./ServicesList";
import AnimatedDiv from "./AnimatedDiv";
import Typography from "@mui/material/Typography";
import {
  Link,
  NavLink,
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import BannerImage from "./BannerImage";
import eight from "./images/fromBrad/8.jpg";

export default function Services() {
  return (
    <AnimatedDiv>
      <Typography variant="h3" component="div">
        Commercial & Residential Services
      </Typography>
      <BannerImage image={eight} />
      <Typography variant="h6" component="div">
        <ul>
          <li>New Construction</li>
          <li>Remodeling</li>
          <li>Concrete</li>
          <ul>
            <li>Footings</li>
            <li>Foundations</li>
            <li>Flatwork</li>
          </ul>
          <li>Excavation & Layout</li>
          <ul>
            <li>Basements</li>
            <li>Sewer Lines</li>
            <li>Water Lines</li>
            <li>Power Lines</li>
            <li>Parking Lots</li>
            <li>Storm Drains</li>
            <li>Street Improvements</li>
            <li>Subdivision Development</li>
          </ul>
          <li>Septic Systems Design & Installation</li>
          <ul>
            <li>Perc Test</li>
            <li>Soil Test</li>
            <li>Repairs & Upgrades</li>
          </ul>
          <li>Demo</li>
          <li>Landscaping</li>
          <ul>
            <li>Fencing</li>
            <li>Spraying & Fertilization</li>
          </ul>
          <li>Trucking</li>
          <ul>
            <li>Dump Truck</li>
            <li>Reefer & Dry Van</li>
            <li>Heavy Equipment Transporting</li>
            <li>Flat Bedding</li>
            <li>Side Dumps & Belly Dumps</li>
          </ul>
        </ul>
        <div id="serviceHeader" />
      </Typography>
      <Outlet />
    </AnimatedDiv>
  );
}

const List = ({ listItemText }) => {
  return (
    <ul style={{ columns: 2 }}>
      {listItemText.map((text) => {
        return (
          <Typography fontSize={17} key={text}>
            <li>{text}</li>
          </Typography>
        );
      })}
    </ul>
  );
};
