import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import logo from "./images/logo.png";
import "./NavigationBar.scss";
import { Link, useResolvedPath, useMatch, useLocation } from "react-router-dom";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";

const pages = [
  { label: "Home", path: "/" },
  { label: "Services", path: "services" },
  { label: "Pictures", path: "pictures" },
  { label: "Contact", path: "contact" },
  { label: "Employment", path: "employment" },
  { label: "Calculators", path: "calculators" },
];

const logoMarginLeft = 55;

const NavigationBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleHamburgerTap = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleHamburgerChildTap = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="sticky"
      color="primary"
      sx={{ justifyContent: "center", flexDirection: "row" }}
    >
      <Toolbar
        sx={{
          minWidth: { xs: undefined, md: 800 },
          flexGrow: 1,
          maxWidth: 900,
        }}
      >
        <img src={logo} className="logo" />
        <a
          href="tel:435-469-0564"
          style={{
            textDecoration: "none",
            color: "inherit",
            marginLeft: logoMarginLeft,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              //https://mui.com/system/display/
              sx={{ color: "#2a2c40", lineHeight: 1 }}
            >
              Parish Construction
            </Typography>
            <Typography
              noWrap
              component="div"
              //https://mui.com/system/display/
              sx={{ color: "#2a2c40", lineHeight: 1, fontSize: 14 }}
            >
              & Property Maintenance
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <PhoneRoundedIcon sx={{ marginRight: "3px", fill: "#2a2c40" }} />

              <Typography sx={{ textDecoration: "underline" }}>
                435-469-0564
              </Typography>
            </div>
          </div>
        </a>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "flex-end",
            display: { xs: "flex", md: "none" },
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleHamburgerTap}
            color="inherit"
            sx={{ fill: "#2a2c40" }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleHamburgerChildTap}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <Link
                to={page.path}
                key={page.label}
                style={{ textDecoration: "none" }}
              >
                <MenuItem key={page.label} onClick={handleHamburgerChildTap}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "flex-end",
            display: { xs: "none", md: "flex" },
          }}
        >
          {pages.map((page) => (
            <ActiveableLink to={page.path} key={page.label}>
              <Button
                onClick={handleHamburgerChildTap}
                sx={{ my: 2, color: "#2a2c40", display: "block" }}
                disableRipple
              >
                {page.label}
              </Button>
            </ActiveableLink>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const ActiveableLink = ({ children, to, ...props }) => {
  const currentPathName = useLocation().pathname;
  const currentPathNameNoSlashes = currentPathName.replaceAll("/", "");

  let match =
    currentPathNameNoSlashes.includes(to) ||
    (currentPathName === "/" && to === "/");

  return (
    <Link
      {...props}
      style={{
        backgroundColor: match ? "#d2e9f9" : undefined,
        textDecoration: "none",
      }}
      to={to}
    >
      {children}
    </Link>
  );
};

export default NavigationBar;
