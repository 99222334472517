import { AnimatePresence, motion } from "framer-motion";

function AnimatedDiv({ children }) {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}

export default AnimatedDiv;
