import Typography from "@mui/material/Typography";
import AnimatedDiv from "./AnimatedDiv";

export default function () {
  return (
    <AnimatedDiv>
      <Typography variant="h4" component="div" sx={{ paddingBottom: "24px" }}>
        Excavating
      </Typography>

      <Typography>
        Info and pictures about Excavating. Info and pictures about Excavating.
        Info and pictures about Excavating.
      </Typography>
    </AnimatedDiv>
  );
}
