import Typography from "@mui/material/Typography";
import AnimatedDiv from "./AnimatedDiv";

export default function () {
  return (
    <AnimatedDiv>
      <Typography variant="h4" component="div" sx={{ paddingBottom: "24px" }}>
        Handyman Work
      </Typography>

      <Typography>
        Info and pictures about handyman stuff. Info and pictures about handyman
        stuff. Info and pictures about handyman stuff. Info and pictures about
        handyman stuff. Info and pictures about handyman stuff.
        <br />
        <br />
        Info and pictures about handyman stuff.
      </Typography>
    </AnimatedDiv>
  );
}
