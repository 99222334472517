import Typography from "@mui/material/Typography";
import teamImage from "./images/fromBrad/3.jpg";
import AnimatedDiv from "./AnimatedDiv";
import ServicesList from "./ServicesList";
import BannerImage from "./BannerImage";

const Home = () => {
  return (
    <AnimatedDiv>
      <Typography variant="h3" component="div" sx={{ paddingBottom: "24px" }}>
        Home
      </Typography>

      <BannerImage image={teamImage} style={{ marginTop: 0 }} />
      <Typography>
        <br />
        Parish Construction was started by Brad Parish in the late 1990's and
        has grown into a fully diverse company. We are a licensed, insured and
        bonded general contractor in the state of Utah, Arizona, and Idaho. We
        specialize in many construction services such as new construction,
        excavation, concrete, landscaping and trucking.
        <br />
        <br />
        Over the years our knowledge has grown to encompass the full spectrum of
        the construction industry. With a diversified portfolio of many
        residential and commercial clients, to our cities, state, and Federal
        Government, special service districts and private companies.
        <br />
        <br />
        Our employees are hard working and dedicated to the company, bringing a
        vast array of experience including highly skilled foremen, equipment
        operators, CDL-licensed truck drivers and certified wastewater and soil
        tester.
        <br />
        <br />
        We take pride in our projects coming in on time and on budget, while
        crews work safely and take all precautions to make sure employees and
        public go home safe to their families.
      </Typography>
    </AnimatedDiv>
  );
};

export default Home;
